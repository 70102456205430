.toggleButtons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    z-index: 5;
    position:fixed;
    left: 5px;
    
}

.centerSpan {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.MuiToggleButton-root {
    display: flex !important;
    margin-top:4px;
}

.welcome {
    margin-top: 10px;
    z-index: 5;
    position:fixed;
    width: 50vw;
    margin-left: 25vw;
    text-align: center;
    font-size: 8vw;
}

@media (max-width: 600px) {
    .toggleButtons {
        width: 100vw;
        margin-top:4px;
        left:0px;
        align-items: center;
    }
    .MuiToggleButton-root {
        width: 13vw !important;
        padding: 0px;
    }
}