
::-webkit-scrollbar {
  display: none;
}

.Canvas {
  width: 100vw;
  height: calc(100vh - 64px);
  justify-content: center;
}

:link { color: #000; }
:visited { color: #59c135; }