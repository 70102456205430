.Grid {
  /* width: 960px;
  height: 800px; */
  display: grid;
  /* grid-template-columns: repeat(120, 8px); */
  
  gap: 0;
  column-gap: 0;
  row-gap:0;
  padding: 0;
}
.Canvas {
  width: 100vw;
  height: calc(100vh - 64px);
}